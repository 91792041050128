<template>
    <b-card v-if="'id' in cwo">
        <form @submit.prevent="addPartRequest">
            <h3>Part requests</h3>
            <table v-if="'partRequests' in cwo" class="table table-bordered table-cwo">
                <tr>
                    <th class="number">{{ $t("forms.no") }}</th>
                    <th>{{ $t("forms.partNumber") }}</th>
                    <th>{{ $t("forms.description") }}</th>
                    <th>{{ $t("forms.quantity") }}</th>
                    <th>{{ $t("forms.remark") }}</th>
                    <th class="actions">{{ $t("forms.actions") }}</th>
                </tr>
                <tr v-for="(item, index) in cwo.partRequests" :key="index">
                    <td>
                        {{ index + 1 }}
                    </td>
                    <td>
                        {{ item.partNumber }}
                    </td>
                    <td>
                        {{ item.description }}
                    </td>
                    <td>
                        {{ item.quantity }}
                    </td>
                    <td>
                        {{ item.remark }}
                    </td>
                    <td>
                        <delete-btn v-if="!item.id" @pressDelete="deleteItem(index)"/>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <b-form-input
                            v-model="partRequestPartNumber"
                            id="partRequestPartNumber"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.partRequestPartNumber.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <b-form-input
                            v-model="partRequestDescription"
                            id="partRequestDescription"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.partRequestDescription.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <b-form-input
                            v-model="partRequestQuantity"
                            id="partRequestQuantity"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.partRequestQuantity.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </td>
                    <td>
                        <b-form-input v-model="partRequestRemark" id="partRequestRemark"/>
                    </td>
                    <td>
                        <b-button variant="gray" @click="addPartRequest()">
                            <BootstrapIcon icon="plus" size="1x"/>
                            Add
                        </b-button>
                    </td>
                </tr>
            </table>
        </form>
    </b-card>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {numeric, required} from "@vuelidate/validators";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";

export default {
    components: {
        DeleteBtn,
    },
    props: {
        cwoObject: {Object, required: true},
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            partRequestDescription: {required},
            partRequestPartNumber: {required},
            partRequestQuantity: {required, numeric},
        };
    },
    data() {
        return {
            cwo: this.cwoObject,
            partRequestDescription: null,
            partRequestPartNumber: null,
            partRequestQuantity: null,
            partRequestRemark: null
        };
    },
    methods: {
        async addPartRequest() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.cwo.partRequests.push({
                    description: this.partRequestDescription,
                    partNumber: this.partRequestPartNumber,
                    quantity: parseInt(this.partRequestQuantity),
                    remark: this.partRequestRemark,
                });
                this.$emit("objectChange", this.cwo.partRequests);
            }
        },
        deleteItem(index) {
            this.cwo.partRequests.splice(index, 1);
            this.$emit("objectChange", this.cwo.partRequests);
        },
    },
};
</script>
